import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
import {sync} from 'vuex-router-sync';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import {Spreadsheet, SpreadsheetSheet, SpreadsheetInstaller} from '@progress/kendo-spreadsheet-vue-wrapper';
import {Map, MapMarker, MapLayer, MapInstaller} from '@progress/kendo-map-vue-wrapper';
import {Chat, ChatInstaller} from '@progress/kendo-chat-vue-wrapper';
import {Upload, UploadInstaller} from '@progress/kendo-upload-vue-wrapper';
import {Editor} from '@progress/kendo-editor-vue-wrapper';
import {EditorInstaller} from '@progress/kendo-editor-vue-wrapper';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueNumerals from 'vue-numerals';
import LottieAnimation from 'lottie-web-vue';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import MapChart from 'vue-map-chart';
import VueI18n from 'vue-i18n';

import {
    Chart,
    ChartSeriesItem,
    Sparkline,
    SparklineSeriesItem,
    StockChart,
    ChartInstaller
} from '@progress/kendo-charts-vue-wrapper';

Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: 'mdi' })

Vue.config.productionTip = false;
Vue.use(SpreadsheetInstaller);
Vue.use(MapInstaller);
Vue.use(ChatInstaller);
Vue.use(UploadInstaller);
Vue.use(EditorInstaller);
Vue.use(ChartInstaller);
Vue.use(vueNumeralFilterInstaller, {locale: 'it'});
Vue.use(VueMoment, { moment });
Vue.use(LottieAnimation);
Vue.use(MapChart);
Vue.use(VueI18n);

var numeralObjConfig = {config: {globalProperties: {}}};
VueNumerals(numeralObjConfig);

Vue.prototype.$numerals = numeralObjConfig.config.globalProperties.numeralFormat;
Vue.prototype.$window = window;

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

Vue.filter('formatDate', function (date) {
    const tenantFormat = store.get("tenantConfiguration/dateFormatting");
    return !!date? moment(date).utc().format(tenantFormat) : 'n.a.';
});

Vue.filter('formatTime', function (date) {
    return moment(date).format("h:mm a");
});

Vue.filter('tenantCurrency', (value, naOnZeroOrNull = false, currency = null) => {
    if (!!naOnZeroOrNull && !value) { return "n.a."; }
    const tenantCurrencySymbol = store.get("tenantConfiguration/currencySymbol");
    return `${currency ?? tenantCurrencySymbol}${numeral(value).format('0,0.00')}`;
});

Vue.filter('percentage', function (value) {
    return `${numeral(value).format('0,0.00')}%`;
});

Vue.filter('formatted', function (value) {
    return `${numeral(value).format('0,0.00')}`;
});

window.lang = localStorage.getItem('lang');
// import dictionary from '../src/assets/dictionary/dictionary.json';

// Vue.filter('i18n', (key = '') => {
//     console.log("Traduzione di:", key, "è", (dictionary[window.lang ?? 'en-US'] ?? {})[key] ?? key);
//     return (dictionary[window.lang ?? 'en-US'] ?? {})[key] ?? key 
// });

sync(store, router);

import Keycloak from 'keycloak-js';

const messages = {
    "en-US" : {
        releaseNotes: "Release Notes",
        selectionIsEmpty: "Selection is empty. Select a document",
        printInstrDocs: "Print Instructional Documents",
        selectSender: "Select a Sender",
        sender: "Sender",
        customerReference: "Customer reference",
        codValue: "COD value",
        selectionIsRequired: "Selection is required",
        contentDescription: "Content description",
        contentDescriptionRequired: "Description is required",
        pickASender: "Pick a sender",
        packageDetailsOf: "Package details of ",
        widthCm: "Width (cm)",
        widthRequired: "Width is required",
        lengthCm: "Length (cm)",
        lengthRequired: "Length is required",
        heightCm: "Height (cm)",
        heightRequired: "Height is required",
        weightKg: "Weight (kg)",
        weightRequired: "Weight is required",
        addPackage: "Add Package",
        package: "Package",
        packages: "Packages",
        totalWeight: "Total weight",
        noPackagesFound: "No packages found",
        enterValidDecimalNumber: "Enter a valid decimal number",
        tel: "Phone",
        missingEmail: "Missing Email",
        invalidEmail: "Invalid Email",
        missingPhone: "Missing Phone",
        specialInstructions: "Special Instructions",
        shippingDoc: "Shipping Doc.",
        recipient: "Recipient",
        documentCustomer: "Document Holder",
        numero: "Number",
        cancelShipment: "Cancel Shipment?",
        printed : "Printed",
        canceled : "Canceled",
        generatedWithErrors : "Generated with Errors",
        selectOneOrMoreTransportDocuments: "Select one or more transport documents to print their TNT label",
        shipmentStatuses: "Shipment Statuses",
        alreadyHandledDocuments: "Non selectable documents have already been handled in a Shipment",
        cancelOrReprint: "Cancel or reprint shipment labels",
        remakeShipment: "Set this shipment again?",
        documentsToPrint: "Transport Documents",
        printedShipments: "Shipments",
        selectOne: "Please select at least one document",
        selectSameCustomer: "Please select docs belonging to the same document holder",
        selectSameRecipient: "Please select docs belonging to the same recipient",
        copyFromParent: "Copy From Parent",
        timeZone: "Time Zone",
        customerTimeZone: "Customer Time Zone :",
        customerTime: "Customer Time :",
        yourTime: "Your Time :", 
        reload: "Reload",
        minOrder: "Min. Order",
        showInStoreLocator: "Show in Store Locator",
        expectedChildren: "Expected Children",
        missingFields: "Missing Fields",
        rows: "Rows",
        noDescri: "No description",
        cart: "Cart",
        changeLang: "Language",
        shipTo: "Ship to",
        orderCustomer: "Order Customer",
        childrenCustomer: "Children Customer",
        allCustomers: "All Customers",
        informations: "Informations",
        target: "Target",
        goals: "Obiettivi",
        calendar: "Calendar",
        checkCoupon: "Check Coupon",
        confirmOrderState: "Confirm Order State",
        customersChildren: "Customer's Children",
        executiveClient: "Executive Client",
        excludeFromStats: "Exclude from Stats",
        fax: "FAX",
        gender: "Gender",
        hasPaymentProblems: "has Payment Problems",
        itemCode: "Item Code",
        leadStatus: "Lead Status",
        leadValue: "Lead Value",
        mailOnOrder: "Mail on Order",
        merchandising: "Merchandising",
        messages: "Messages",
        minimumOrder: "Minimum Order",
        physicalPerson: "Physical Person",
        sdi: "SDI",
        tables: "Tables",
        tasks: "Tasks",
        taskAndMessages: "Task and Messages",
        //! A
        actions: "Actions",
        active: "Active",
        activationDate: "Activation date",
        add: "ADD",
        address: "Address",
        addresses: "Addresses",
        accountingEmail: "Accounting Email {r}",
        accountingType: "Accounting Type",
        addToWhitelist: "Add to Whitelist",
        agent: "Agent",
        agents: "Agents",
        alertMetodo: "In the current selection there is at least an order with the status ",
        all: "ALL",
        allDone: "All Done",
        amount: "Amount",
        ampersand: "Ampersand (&)",
        anchor: "Anchor",
        anchors: "Anchors",
        applicant: "Applicant",
        apply: "Apply",
        applyBudgetError: "Invalid customerCollection! Apply filters properly",
        applyChangeTo: "Do you want to apply this change to all customers or only those without a preset budget?",
        applyParam: "Apply {param}",
        applyTaxes: "Apply Taxes",
        applyTaxJar: "Apply Taxes From TaxJar",
        applyToParam: "Apply to {param}",
        assignedTo: "Assigned To",
        authorization: "Authorization",
        authorizeTo: "Authorize to",
        author: "Author",
        //! B
        b2bEmail: "B2B Email",
        b2bCredentialsHeader: "B2B Credentials",
        b2bCredentialsMessage: "You are about to create B2B credentials for this customer. Are you sure you want to proceed?",
        b2bAgentsHeader: "Sync ownership to Prestashop",
        b2bAgentsMessage: "You are about to sync customers of this agents to Prestashop. Are you sure you want to proceed?",
        barcode: "Barcode",
        barcodeLength: "Barcode must be exactly 11 or 13 characters",
        barcodePrefix: "Barcode Prefix",
        beforeDiscount: "Before Discount",
        birthday: "Birthday",
        bis: "Bis",
        box: "Box",
        brand: "Brand",
        brandCheck: "Brand Check",
        budget: "Budget",
        budgetCY: "Budget CY",
        budgetYTD: "Budget YTD",
        businessName: "Business name {r}",
        //! C
        cancel: "Cancel",
        cannotRecognize: "We cannot recognize these codes",
        catalog: "Catalog",
        catalogs: "Catalogs",
        ceg: "C&L",
        cellphone: "Cellphone",
        chain: "Chain",
        changeItemPicture: "Change Item Picture",
        changePriceCatalog: "Change Price Catalog",
        changeStatus: "Change Status to ",
        channel: "Channel",
        chief: "Chief",
        chiefAgent: "Chief Agent",
        childrenDistri: "Distributor's children",
        city: "City",
        clear: "Clear",
        clone: "Clone",
        close: "Close",
        code: "Code",
        collection: "Collection",
        collectionCharges: "Collection Charges",
        collectionExpenses: "Collection Expenses",
        collectionExpensesR1: "Collection Expenses should be a number",
        collectionExpensesR2: "Collection Expenses should be equal or more than 0",
        collections: "Collections",
        comma: "Comma (,)",
        commercialSpecialist: "Commercial Specialist",
        compileBarcode: "COMPILE BARCODE",
        complete: "Complete",
        completed: "Completed",
        completedTasks: "completed tasks",
        confirm: "Confirm",
        contractualConversionRate: "CONTRACTUAL CONVERSION RATE from ACCOUNTING CURRENCY",
        contractualData: "Contractual Data",
        contactName: "Contact Name",
        country: "Country {r}",
        county: "County {r}",
        crm: "CRM",
        currency: "Currency",
        cutoffOrderDate: "Cutoff Order Date",
        customer: "Customer",
        customerCode: "Customer Code/Name",
        customerMonitoring: "Customer Monitoring",
        customerPaymentProblem: "This customer has payment problems.",
        customers: "Customers",
        //! D
        data: "Data",
        date: "Date",
        day: "Day",
        deadlines: "Deadlines",
        default: "Default",
        delete: "Delete",
        deleteMessage: "You are about to delete this customer. This operation is irreversible, and you will not be able to undo it. Are you sure you want to proceed?",
        deleteParam: "Delete {param}",
        deleteText: "Are you sure you want to delete this {param}?",
        deleteHeader: "Confirm deletion",
        deliveryCharges: "Delivery Charges",
        description: "Description",
        descriptionBudgetAvailability: "The customer's budget, divided by 'statistical tag,' is available as presented here.",
        descriptionLargeScaleBudgetInput: "To input budgets on a large scale, use the blue button located above the table, featuring the icon of a banknote and a small plus sign. Select whether to apply the budget to all customers in the list or only to those who are still missing one.",
        descriptionModifyBudget: "It is possible to modify and/or delete existing budgets or add a new budget associated with an absent statistical tag by filling out the appropriate form.",
        dictionary: "Dictionary",
        disc: "Disc",
        discP: "Disc %",
        discount: "Discount",
        discountAmount: "Discount Amount",
        dismiss: "DISMISS",
        distributor: "Distributor",
        docDate: "Doc Date",
        docType: "Doc Type",
        document: "Header",
        documentDate: "Document Date",
        documentType: "Document Type",
        done: "Done",
        dontInsertParam: "Don't insert {param} or insert it correctly",
        download: "Download",
        downloadAll: "Download All",
        downloadDocuments: "Download Documents",
        downloadExample: "Download Example",
        dropAnchor: "I want to drop the ANCHOR",
        due: "Due",
        //! E
        edit: "Edit",
        editParam: "Edit {param}",
        edi: "EDI",
        email: "Email {r}",
        enasarco: "Enasarco",
        error: "Error",
        errorNewOrder: "There's an error with the Prices. Please verify quantity and row's price.",
        errorTryLater: "Error! Try later",
        estimatedDeliveryDate: "Estimated Delivery Date",
        executor: "Executor",
        expectedStores: "Expected Stores",
        expired: "Expired",
        expiration: "Expiration",
        expirationDate: "Expiration Date",
        explodeGroupCollection: "Explode Item Groups and Collections",
        export: "Export",
        exportToGrid: "Export to Grid",
        expectedValue: "Expected Value",
        //! F
        facebook: "Facebook",
        family: "Family",
        fiscalCode: "Fiscal Code {r}",
        fileInput: "File Input",
        filter: "Filter",
        filters: "Filters",
        financials: "Financials",
        firstName: "First Name",
        footer: "Footer",
        footerMonitoring: "As of {endDate} ({daysYTD} days Year To Date)",
        forceSync: "Force Sync",
        fourDays: "4 Days",
        from: "From",
        fullAddress: "Full Address",
        //! G
        generalData: "General Data",
        gift: "Gift",
        grid: "Grid",
        group: "Group",
        groupIdentifier: "Group Identifier",
        groupOrIndipendent: "Group/Indipendent",
        groups: "Groups",
        //! H
        hasCustomerBudget: "Has Customer Budget",
        hasEngravers: "Has Engravers",
        hasExpiration: "Has an Expiration",
        header: "Header",
        height: "Height",
        hide: "Hide",
        hideParam: "Hide {param}",
        historicalOrder: "Historical Order",
        howManyMonths: "How many months?",
        //! I
        iban: "Iban {r}",
        id: "Id",
        image: "Image",
        inactiveItems: "Inactive Items",
        insertLink: "Insert LINK",
        insertValidParam: "Insert a valid {param}",
        incomplete: "Incomplete",
        inactive: "Inactive",
        individual: "Individual",
        info: "Info",
        infoChannel: "A 'sales channel' refers to the different methods or channels through which a company sells its products or services to customers. It represents the various avenues or platforms utilized by a business to reach and engage with its target market, allowing customers to make purchases. Sales channels can include physical retail stores, e-commerce websites, mobile apps, direct sales teams, wholesalers, distributors, and any other intermediaries or platforms involved in the process of selling products or services. Each sales channel has its unique characteristics, advantages, and customer reach, and companies often employ multiple sales channels to cater to diverse customer preferences and expand their market presence.",
        infoPurchaseCategory: "The term 'business type' refers to the classification or categorization of a company based on its legal structure, ownership, and operational characteristics. It helps define the nature, organization, and governance of a business entity. The business type determines factors such as liability, taxation, ownership rights, and regulatory requirements.",
        infoSector: "The term 'sales field' typically refers to the specific area or domain within the broader field of sales. It encompasses the activities, strategies, and techniques related to selling products or services in a particular industry or market segment. The sales field may include various roles and functions, such as sales representatives, sales managers, sales operations, and sales support personnel. Within the sales field, professionals typically focus on developing and implementing effective sales strategies, building relationships with customers, generating leads, negotiating and closing deals, and achieving sales targets. They may also engage in market research, competitor analysis, and customer relationship management to enhance their sales performance. In summary, the sales field represents the specialized area of expertise and activities related to sales within a specific industry or market segment.",
        internalNotes: "Internal Notes",
        invoicingCustomer: "Invoicing Customer {r}",
        invoicingCustomerLocation: "Invoicing Customer Location",
        invoiceToDifferentCustomer: "Invoice to Different Customer",
        invalidParam: "Invalid {param}",
        items: "Items",
        item: "Item",
        itemsQty: "items quantity",
        itemsPriceResume: "Items: {q}. Min Price: {min}, Max Price: {max}",
        //! J
        //! K
        kit: "Kit",
        kits: "Kits",
        //! L
        labelGlobalBudget: "Global Budget",
        labelHighBudget: "High Budget",
        labelLowBudget: "Low Budget",
        labelWithoutBudget: "Without Budget",
        lang: "Lang",
        language: "Language",
        lastName: "Last Name",
        lastOrderDate: "Last Order Date",
        lastOrderDateInRange: "Last Order Date in Range",
        leaveComment: "Leave a Comment",
        lessThan: "{param} must be less than {qty} characters",
        lfu: "LFU",
        line: "Line",
        listSearch: "List Search",
        loading: "Loading... Please wait",
        loadCsv: "Load CSV",
        loadMore: "Load More",
        loadedValidItems: "Loaded valid items",
        loan: "Loan",
        location: "Location",
        locations: "Locations",
        logsAndMessages: "Logs and Messages",
        //! M
        machine: "Machine",
        machines: "Machines",
        mainInfo: "Main Info",
        mandatoryShippingDate: "Mandatory Shipping Date",
        management: "Management",
        marketingData: "Marketing Data",
        marketingEmail: "Marketing Email",
        marketingFilters: "Marketing Filters",
        maxParam: "Max {param}",
        minNotReached: "Minimum order ({param}) not reached",
        minParam: "Min {param}",
        missing: "MISSING",
        month: "Month",
        monitoring: "Monitoring",
        movingQty: "Moving Qty",
        //! N
        na: "n.a.",
        name: "Name",
        nearestDistributor: "Nearest Distributor",
        neverExpire: "Never Expire",
        newParam: "New {param}",
        next: "Next",
        no: "No",
        noData: "NO DATA 2 SHOW",
        noDataRefresh: "NO DATA - Click to refresh",
        noTasks: "No tasks available. Have a nice day",
        notExecutiveCustomers: "Not Executive Customers",
        notGift: "Not a Gift",
        notLoan: "Not a Loan",
        notObsolete: "Not Obsolete",
        note: "Note",
        notes: "Notes",
        nr: "Nr",
        //! O
        obsolete: "Obsolete",
        obsoleteItems: "Obsolete Items",
        onlyEffectiveCustomers: "Only Effective Customers",
        onlyExecutiveCustomers: "Only Executive Customers",
        onlyPotentialLeads: "Only Potential Leads",
        open: "Open",
        openDraft: "OPEN DRAFT",
        order: "Order",
        orderDate: "Order Date",
        orderHasBeen: "This Order has been",
        orderIs: "This Order is a",
        orderLanguage: "Order Language",
        orderRows: "Order Rows",
        orders: "Orders",
        orderStatisticalTag: "Order Statistical Tag",
        orderStatus: "Order Status",
        orderStatusText: "This Order Status is",
        orderType: "Order Type",
        orderWaiting: "This Order is waiting for",
        ordersContent: "Orders Content",
        ordersPY: "Orders PY",
        ordersYTD: "Orders YTD",
        ourChampion: "Our Champion",
        other: "Other",
        ownership: "Ownership",
        //! P
        packagingType: "Packaging Type",
        pallet: "Pallet",
        palletHeight: "Pallet height",
        palletHeightCm: "Pallet max height (cm)",
        parent: "Parent",
        parentCode: "Parent Code",
        partner: "Partner",
        payment: "Payment",
        paymentInformations: "Payment Informations",
        paymentMethod: "Payment Method {r}",
        paymentMethods: "Payment Methods",
        paymentTerm: "Payment Term {r}",
        paymentTerms: "Payment Therms",
        percentage: "Percentage",
        phone: "Phone",
        pipe: "Pipe (|)",
        pleaseConfirm: "Please confirm whether you would like to proceed with this action.",
        po: "PO",
        postalCode: "Postal Code",
        postalCodes: "Postal Codes",
        potential: "Potential",
        pricaCatalogSubtitle: "Do not create unnecessary duplicates and proceed with the creation of a new price list",
        pricaCatalogTitle: "Do you need a new price list?",
        preview: "Preview",
        previous: "Previous",
        price: "Price",
        priceCatalog: "Price Catalog",
        priceCatalogs: "Price Catalogs",
        printCredentialsLabel: "Print Credentials Label",
        priorityOrders: "Priority Orders",
        processed: "Processed",
        processedUP: "PROCESSED",
        province: "Provincia",
        purchaseCategory: "Purchase Category",
        //! Q
        qty: "Qty",
        qtyBudgetCY: "Qty on Budget CY",
        qtyOrdersYTD: "Qty on Orders YTD",
        queryApplyBudget: "Which customers do you want to apply the budget?",
        quantity: "Quantity",
        //! R
        recDate: "Rec Date",
        region: "Region",
        registrationDate: "Registration date",
        remove: "Remove",
        repeated: "Repeated",
        required: "{param} field is required",
        reset: "Reset",
        reviewNotes: "Review notes before changing status to 'Work in Progress'",
        revertOrderStatusTo: "Revert Order status to",
        rowTotal: "Row Total",
        rowsTotalAfterDiscSurc: "Rows total after discount/surcharge",
        //! S
        sales: "Sales",
        saleTaxes: "Sale Taxes",
        save: "Save",
        saveOrderRows: "Save Order Rows",
        search: "Search",
        sector: "Sector",
        select: "Select",
        selectLang: "Select language",
        selectTenant: "Select the Tenant",
        selectValidStatisticalTag: "Select a valid statistical Tag",
        selection: "Selection",
        sem: "SEM",
        semicolon: "Semicolon (;)",
        send: "Send",
        separator: "Separator",
        setAs: "Set As",
        setFilterMetodo: "Set a filter to export data to Metodo",
        shipment: "Shipment",
        shipmentDate: "Shipment Date",
        shippingCustomer: "Shipping Customer",
        shippingCustomerLocation: "Shipping Customer Location",
        shippingTo: "Shipping To",
        shipToDifferentCustomer: "Ship to Different Customer",
        shopName: "Shop Name {r}",
        show: "Show",
        showAll: "Show all",
        stampDuty: "Stamp Duty",
        state: "State",
        statCategory: "Stat Category",
        statisticalTag: "Statistical Tag",
        status: "Status",
        step1custTitle: "Sector, Channel and Purchase Category",
        step2custTitle: "Required informations",
        step4custTitle: "Financials info",
        stringSearch: "String Search",
        subAgent: "Sub-Agent",
        subTotal: "Sub Total",
        subtotalDeliveryCollection: "Subtotal Delivery Collection",
        summary: "Summary",
        surcharge: "Surcharge",
        surDis: "Surcharge/Discount",
        surDisPerc: "Surcharge/Discount %",
        suffix: "Suffix",
        supervisorAgent: "Supervisor Agent",
        switchToParam: "Switch to {param}",
        //! T
        tags: "Tags",
        tax: "Tax",
        taxEligible: "Tax Eligible",
        taxLiableForParam: "Tax Liable For {param}",
        taxPercentage: "Tax Percentage",
        taxable: "Taxable",
        taxes: "Taxes",
        tenant: "Tenant",
        title: "Title",
        to: "To",
        toDo: "To Do",
        today: "Today",
        total: "Total",
        totalAmount: "Total Amount",
        totalQuantity: "Total Quantity",
        totalWithTaxes: "Total with Taxes",
        transmitted: "TRANSMITTED",
        translation: "Translation",
        translations: "Translations",
        travel: "Travel",
        type: "Type",
        typeOrCopyPaste: "Type or Copy/Paste",
        //! U
        unified: "Unified",
        unifyScale: "Unify Scale",
        unset: "Unset",
        update: "Update",
        updateText: "Are you sure you want to update this {param}?",
        upload: "Upload",
        uploadCsv: "Upload CSV",
        uploadCsvDoc: "Upload CSV Document",
        user: "User",
        username: "Username",
        users: "Users",
        //! V
        validItems: "Valid Items",
        validParam: "{param} must be valid",
        value: "Value",
        vat: "VAT",
        vatAssessment: "VAT Assessment",
        vatCode: "VAT Code",
        vatNumber: "VAT Number {r}",
        vatPercentage: "VAT Percentage",
        vatPerc: "VAT %",
        vatRate: "VAT Rate {r}",
        visible: "Visible",
        //! W
        website: "Website",
        week: "Week",
        weight: "Weight",
        whitespaces: "No spaces are allowed",
        whenReady: "When ready, click on the button to",
        with: "With",
        withBudget: "With Budget",
        withParam: "With {param}",
        withoutBudget: "Without Budget",
        withoutParam: "Without {param}",
        workInProgress: "WORK IN PROGRESS",
        //! X
        //! Y
        year: "Year",
        yes: "Yes",
        //! Z
        zone: "Zone",
        massiveDuplicate: "Massive Duplication"
    },
    "pt-BR" : {
        copyFromParent: "Copy From Parent",
        timeZone: "Time Zone",
        customerTimeZone: "Customer Time Zone :",
        releaseNotes: "Notas de versão",
        selectionIsEmpty: "A seleção está vazia. Selecionar um documento",
        printInstrDocs: "Imprimir documentos de instrução",
        selectSender: "Selecionar um Expedidor",
        sender: "Expedidor",
        customerReference: "Referência do cliente",
        codValue: "Valor da marca",
        selectionIsRequired: "A seleção é obrigatória",
        contentDescription: "Descrição dos produtos",
        contentDescriptionRequired: "A descrição é obrigatória",
        pickASender: "Selecionar um Expedidor",
        packageDetailsOf: "Detalhes de pacotes de documentos ",
        widthCm: "Profundidade (cm)",
        widthRequired: "A profundidade é obrigatória",
        lengthCm: "Largura (cm)",
        lengthRequired: "A largura é obrigatória",
        heightCm: "Altura (cm)",
        heightRequired: "A altura é obrigatória",
        weightKg: "Peso (kg)",
        weightRequired: "O peso é obrigatório",
        addPackage: "Adicionar Pacote",
        package: "Pacote",
        packages: "Pacotes",
        totalWeight: "Peso total",
        noPackagesFound: "Nenhum pacote encontrado",
        enterValidDecimalNumber: "Digite um número decimal válido",
        tel: "Telefone",
        missingEmail: "Email ausente",
        invalidEmail: "Email inválido",
        missingPhone: "Telefone ausente",
        specialInstructions: "Instruções especiais",
        shippingDoc: "Documento de expedição",
        recipient: "Receptor",
        documentHolder: "Suporte de Documentos",
        numero: "Número",
        cancelShipment: "Cancelar a remessa?",
        printed : "Impresso",
        canceled : "Cancelado",
        generatedWithErrors : "Gerado com erros",
        selectOneOrMoreTransportDocuments: "Selecione um ou mais documentos de transporte para imprimir sua etiqueta TNT",
        alreadyHandledDocuments: "Os documentos que não podem ser selecionados já foram tratados em uma remessa",
        shipmentStatuses: "Estados de remessa",
        documentsToPrint: "Documentos de transporte",
        printedShipments: "Expedições",
        cancelOrReprint: "Cancelar ou reimprimir etiquetas de remessa",
        remakeShipment: "Defina novamente essa remessa?",
        selectOne: "Selecione pelo menos um documento",
        selectSameCustomer: "Selecione os documentos pertencentes ao mesmo suporte",
        selectSameRecipient: "Selecione os documentos pertencentes ao mesmo receptor",
        timeZone: "Fuso horário",
        customerTimeZone: "Fuso horário do cliente :",
        customerTime: "Customer Time :",
        yourTime: "Tempo do cliente :",
        reload: "Recarregar",
        minOrder: "Mínimo Pedido",
        showInStoreLocator: "Mostrar no localizador de lojas",
        expectedChildren: "Filhos previstos",
        missingFields: "Campos ausentes",
        rows: "Fileiras",
        noDescri: "Nenhuma descrição",
        cart: "Carrinho",
        changeLang: "Idioma",
        shipTo: "Enviar para",
        orderCustomer: "Pedido de cliente",
        childrenCustomer: "Children Customer",
        allCustomers: "Todos os clientes",
        informations: "Informações",
        target: "Objetivo",
        goals: "Objetivos",
        checkCoupon: "Verificar cupom",
        confirmOrderState: "Confirmar Estado do Pedido",
        customersChildren: "Customer's Children",
        executiveClient: "Cliente Executivo",
        excludeFromStats: "Excluir das Estatísticas",
        fax: "FAX",
        gender: "Gênero",
        hasPaymentProblems: "Tem Problemas de Pagamento",
        itemCode: "Código do Item",
        leadStatus: "Status do Lead",
        leadValue: "Valor do Lead",
        mailOnOrder: "E-mail no Pedido",
        merchandising: "Merchandising",
        messages: "Mensagens",
        minimumOrder: "Pedido Mínimo",
        physicalPerson: "Pessoa Física",
        sdi: "SDI",
        tables: "Tabelas",
        task: "Tarefas",
        taskAndMessages: "Tarefas e Mensagens",
        //! A
        actions: "Ações",
        active: "Ativo",
        activationDate: "Data de ativação",
        add: "ADICIONAR",
        address: "Endereço",
        addresses: "Endereços",
        accountingEmail: "E-mail de contabilidade {r}",
        accountingType: "Tipo de contabilidade",
        addToWhitelist: "Adicionar à lista de permissões",
        agent: "Agente",
        agents: "Agentes",
        alertMetodo: "Na seleção atual, há pelo menos um pedido com o status ",
        all: "TODOS",
        allDone: "Tudo pronto",
        amount: "Montante",
        ampersand: "E comercial (&)",
        anchor: "Âncora",
        anchors: "Âncoras",
        applicant: "Candidato",
        apply: "Aplicar",
        applyBudgetError: "Coleção de clientes inválida! Aplique os filtros corretamente",
        applyChangeTo: "Você quer aplicar esta mudança a todos os clientes ou apenas àqueles sem orçamento predefinido?",
        applyParam: "Aplicar {param}",
        applyTaxes: "Aplicar impostos",
        applyTaxJar: "Aplicar impostos do TaxJar",
        applyToParam: "Aplicar para {param}",
        assignedTo: "Atribuído a",
        authorization: "Autorização",
        authorizeTo: "Autorizar para",
        author: "Autor",
        //! B
        b2bEmail: "E-mail B2B",
        b2bCredentialsHeader: "B2B Credentials",
        b2bCredentialsMessage: "You are about to create B2B credentials for this customer. Are you sure you want to proceed?",
        b2bAgentsHeader: "Sync ownership to Prestashop",
        b2bAgentsMessage: "You are about to sync customers of this agents to Prestashop. Are you sure you want to proceed?",
        barcode: "Código de barras",
        barcodeLength: "O código de barras deve ter exatamente 11 ou 13 caracteres",
        barcodePrefix: "Prefixo do código de barras",
        beforeDiscount: "Antes do desconto",
        birthday: "Aniversário",
        bis: "Bis",
        box: "Caixa",
        brand: "Marca",
        brandCheck: "Verificação da marca",
        budget: "Orçamento",
        budgetCY: "Orçamento CY",
        budgetYTD: "Orçamento YTD",
        businessName: "Nome da empresa {r}",
        //! C
        cancel: "Cancelar",
        cannotRecognize: "Não reconhecemos esses códigos",
        catalog: "Catálogo",
        catalogs: "Catálogos",
        ceg: "C&L",
        cellphone: "Celular",
        chain: "Cadeia",
        changeItemPicture: "Alterar imagem do item",
        changePriceCatalog: "Alterar catálogo de preços",
        changeStatus: "Alterar status para ",
        channel: "Canal",
        chief: "Chefe",
        chiefAgent: "Agente Chefe",
        childrenDistri: "Distribuidores filhos",
        city: "Cidade",
        clear: "Limpar",
        clone: "Clonar",
        close: "Fechar",
        code: "Código",
        collection: "Coleção",
        collectionCharges: "Taxas de cobrança",
        collectionExpenses: "Despesas de cobrança",
        collectionExpensesR1: "Despesas de cobrança devem ser um número",
        collectionExpensesR2: "Despesas de cobrança devem ser iguais ou maiores que 0",
        collections: "Coleções",
        comma: "Comma (,)",
        commercialSpecialist: "Especialista Comercial",
        compileBarcode: "COMPILAR CÓDIGO DE BARRAS",
        complete: "Completar",
        completed: "Concluído",
        completedTasks: "tarefas concluídas",
        confirm: "Confirmar",
        contractualConversionRate: "TAXA DE CONVERSÃO CONTRATUAL da MOEDA DE CONTABILIDADE",
        contractualData: "Dados contratuais",
        contactName: "Nome do contato",
        country: "País {r}",
        county: "Município {r}",
        crm: "CRM",
        currency: "Moeda",
        cutoffOrderDate: "Data de corte do pedido",
        customer: "Cliente",
        customerCode: "Código/Nome do Cliente",
        customerMonitoring: "Monitoramento do Cliente",
        customerPaymentProblem: "Este cliente tem problemas de pagamento.",
        customers: "Clientes",
        //! D
        data: "Dados",
        date: "Data",
        day: "Dia",
        deadlines: "Prazos",
        default: "Padrão",
        delete: "Delete",
        deleteMessage: "You are about to delete this customer. This operation is irreversible, and you will not be able to undo it. Are you sure you want to proceed?",
        deleteHeader: "Confirm deletion",
        deleteParam: "Excluir {param}",
        deleteText: "Tem certeza que deseja excluir este {param}?",
        deliveryCharges: "Taxas de entrega",
        description: "Descrição",
        descriptionBudgetAvailability: "O orçamento do cliente, dividido por 'tag estatística', está disponível conforme apresentado aqui.",
        descriptionLargeScaleBudgetInput: "Para inserir orçamentos em larga escala, use o botão azul localizado acima da tabela, apresentando o ícone de uma cédula e um pequeno sinal de mais. Selecione se deseja aplicar o orçamento a todos os clientes da lista ou apenas àqueles que ainda estão sem um.",
        descriptionModifyBudget: "É possível modificar e/ou excluir orçamentos existentes ou adicionar um novo orçamento associado a uma tag estatística ausente preenchendo o formulário apropriado.",
        dictionary: "Dicionário",
        disc: "Disco",
        discP: "Disco %",
        discount: "Desconto",
        discountAmount: "Montante de desconto",
        dismiss: "DISMISS",
        distributor: "Distribuidor",
        docDate: "Data do Documento",
        docType: "Tipo de Documento",
        document: "Documento",
        documentDate: "Data documento",
        documentType: "Tipo de Documento",
        done: "Feito",
        dontInsertParam: "Não insira {param} ou insira corretamente",
        download: "Baixar",
        downloadAll: "Baixar Todos",
        downloadDocuments: "Baixar Documentos",
        downloadExample: "Baixar Exemplo",
        dropAnchor: "I want to drop the ANCHOR",
        due: "Devido",
        //! E
        edit: "Editar",
        editParam: "Editar {param}",
        edi: "EDI",
        email: "E-mail {r}",
        enasarco: "Enasarco",
        error: "Erro",
        errorNewOrder: "Há um erro com os Preços. Por favor, verifique a quantidade e o preço da linha.",
        errorTryLater: "Erro! Tente mais tarde",
        estimatedDeliveryDate: "Data estimada de entrega",
        executor: "Executor",
        expectedStores: "Expected Stores",
        expired: "Expirado",
        expiration: "Expiração",
        expirationDate: "Data de expiração",
        explodeGroupCollection: "Explodir grupos de itens e coleções",
        export: "Exportar",
        exportToGrid: "Exportar para grade",
        expectedValue: "Valor esperado",
        //! F
        facebook: "Facebook",
        family: "Família",
        fiscalCode: "Código Fiscal {r}",
        fileInput: "Entrada de Arquivo",
        filter: "Filtro",
        filters: "Filtros",
        financials: "Financeiro",
        firstName: "Primeiro Nome",
        footer: "Rodapé",
        footerMonitoring: "Em {endDate} ({daysYTD} dias do ano até a data)",
        forceSync: "Forçar Sincronização",
        fourDays: "4 Dias",
        from: "De",
        fullAddress: "Endereço Completo",
        //! G
        generalData: "Dados Gerais",
        gift: "Presente",
        grid: "Grade",
        group: "Grupo",
        groupIdentifier: "Identificador de Grupo",
        groupOrIndipendent: "Grupo/Independente",
        groups: "Grupos",
        //! H
        hasCustomerBudget: "Tem Orçamento de Cliente",
        hasEngravers: "Tem Gravadores",
        hasExpiration: "Tem Expiração",
        header: "Cabeçalho",
        height: "Altura",
        hide: "Ocultar",
        hideParam: "Ocultar {param}",
        historicalOrder: "Pedido Histórico",
        howManyMonths: "Quantos meses?",
        //! I
        iban: "Iban {r}",
        id: "Id",
        image: "Imagem",
        inactive: "Inativo",
        inactiveItems: "Itens Inativos",
        individual: "Indivíduo",
        info: "Informações",
        infoChannel: "Um 'canal de vendas' refere-se aos diferentes métodos ou canais pelos quais uma empresa vende seus produtos ou serviços aos clientes. Representa as diversas avenidas ou plataformas utilizadas por um negócio para alcançar e engajar seu mercado-alvo, permitindo que os clientes façam compras. Os canais de vendas podem incluir lojas físicas, sites de comércio eletrônico, aplicativos móveis, equipes de vendas diretas, atacadistas, distribuidores e quaisquer outros intermediários ou plataformas envolvidos no processo de venda de produtos ou serviços. Cada canal de vendas possui características, vantagens e alcance de clientes únicos, e as empresas geralmente empregam múltiplos canais de vendas para atender às diversas preferências dos clientes e expandir sua presença no mercado.",
        infoPurchaseCategory: "O termo 'tipo de negócio' refere-se à classificação ou categorização de uma empresa com base na sua estrutura legal, propriedade e características operacionais. Ajuda a definir a natureza, organização e governança de uma entidade comercial. O tipo de negócio determina fatores como responsabilidade, tributação, direitos de propriedade e requisitos regulatórios.",
        infoSector: "O termo 'campo de vendas' geralmente se refere à área ou domínio específico dentro do campo mais amplo de vendas. Abrange as atividades, estratégias e técnicas relacionadas à venda de produtos ou serviços em um determinado segmento de mercado ou indústria. O campo de vendas pode incluir várias funções e papéis, como representantes de vendas, gerentes de vendas, operações de vendas e pessoal de suporte de vendas. Dentro do campo de vendas, os profissionais geralmente se concentram em desenvolver e implementar estratégias de vendas eficazes, construir relacionamentos com os clientes, gerar leads, negociar e fechar negócios e atingir metas de vendas. Eles também podem se envolver em pesquisa de mercado, análise de concorrentes e gestão de relacionamento com clientes para melhorar seu desempenho de vendas. Em resumo, o campo de vendas representa a área especializada de expertise e atividades relacionadas a vendas dentro de uma indústria ou segmento de mercado específico.",
        internalNotes: "Internal Notes",
        insertLink: "Inserir LINK",
        insertValidParam: "Insira um {param} válido",
        incomplete: "Incompleto",
        invoicingCustomer: "Cliente de Faturamento {r}",
        invoicingCustomerLocation: "Localização do Cliente de Faturamento",
        invoiceToDifferentCustomer: "Faturar para um Cliente Diferente",
        invalidParam: "{param} inválido",
        items: "Itens",
        item: "Item",
        itemsQty: "quantidade de itens",
        itemsPriceResume: "Itens: {q}. Preço Mínimo: {min}, Preço Máximo: {max}",
        //! J
        //! K
        kit: "Kit",
        kits: "Kits",
        //! L
        labelGlobalBudget: "Orçamento Global",
        labelHighBudget: "Orçamento Alto",
        labelLowBudget: "Orçamento Baixo",
        labelWithoutBudget: "Sem Orçamento",
        lang: "Idioma",
        language: "Idioma",
        lastName: "Sobrenome",
        lastOrderDate: "Data do Último Pedido",
        lastOrderDateInRange: "Data do Último Pedido no Intervalo",
        leaveComment: "Deixe um Comentário",
        lessThan: "{param} deve ser menor que {qty} caracteres",
        lfu: "LFU",
        line: "Linha",
        listSearch: "Pesquisa na Lista",
        loading: "Carregando... Por favor, aguarde",
        loadCsv: "Carregar CSV",
        loadMore: "Carregar Mais",
        loadedValidItems: "Itens válidos carregados",
        loan: "Empréstimo",
        location: "Localização",
        locations: "Localizações",
        logsAndMessages: "Logs e Mensagens",
        //! M
        machine: "Máquina",
        machines: "Máquinas",
        mainInfo: "Informações Principais",
        mandatoryShippingDate: "Data de Envio Obrigatória",
        management: "Gestão",
        marketingData: "Dados de Marketing",
        marketingEmail: "E-mail de Marketing",
        marketingFilters: "Filtros de Marketing",
        maxParam: "Máx {param}",
        minNotReached: "Pedido mínimo ({param}) não atingido",
        minParam: "Mín {param}",
        missing: "FALTANDO",
        month: "Mês",
        monitoring: "Monitoramento",
        movingQty: "Quantidade em movimento",
        //! N
        na: "n.d.",
        name: "Nome",
        nearestDistributor: "Distribuidor mais próximo",
        neverExpire: "Nunca expira",
        newParam: "Novo {param}",
        next: "Próximo",
        no: "Não",
        noData: "SEM DADOS PARA MOSTRAR",
        noDataRefresh: "SEM DADOS - Clique para atualizar",
        noTasks: "Sem tarefas disponíveis. Tenha um bom dia",
        notExecutiveCustomers: "Clientes Não Executivos",
        notGift: "Não é um presente",
        notLoan: "Não é um empréstimo",
        notObsolete: "Não Obsoleto",
        note: "Nota",
        notes: "Notas",
        nr: "Nr",
        //! O
        obsolete: "Obsoleto",
        obsoleteItems: "Itens Obsoletos",
        onlyEffectiveCustomers: "Apenas Clientes Eficazes",
        onlyExecutiveCustomers: "Apenas Clientes Executivos",
        onlyPotentialLeads: "Apenas Leads Potenciais",
        open: "Abrir",
        openDraft: "ABRIR RASCUNHO",
        order: "Pedido",
        orderDate: "Data do Pedido",
        orderHasBeen: "Este Pedido foi",
        orderIs: "Este Pedido é um",
        orderLanguage: "Idioma do Pedido",
        orderRows: "Linhas do Pedido",
        orders: "Pedidos",
        orderStatisticalTag: "Tag Estatística do Pedido",
        orderStatus: "Status do Pedido",
        orderStatusText: "O Status deste Pedido é",
        orderType: "Tipo do Pedido",
        orderWaiting: "Este Pedido está aguardando por",
        ordersContent: "Conteúdo dos Pedidos",
        ordersPY: "Pedidos PY",
        ordersYTD: "Pedidos YTD",
        ourChampion: "Nosso Campeão",
        other: "Outro",
        ownership: "Propriedade",
        //! P
        packagingType: "Tipo de embalagem",
        pallet: "Palete",
        palletHeight: "Altura do palete",
        palletHeightCm: "Altura máxima do palete (cm)",
        parent: "Parente",
        parentCode: "Código do Parente",
        partner: "Parceiro",
        payment: "Pagamento",
        paymentInformations: "Informações de Pagamento",
        paymentMethod: "Método de Pagamento {r}",
        paymentMethods: "Métodos de Pagamento",
        paymentTerm: "Prazo de Pagamento {r}",
        paymentTerms: "Termos de Pagamento",
        percentage: "Percentagem",
        phone: "Telefone",
        pipe: "Pipe (|)",
        pleaseConfirm: "Por favor, confirme se deseja prosseguir com esta ação.",
        po: "PO",
        postalCode: "Código Postal",
        postalCodes: "Códigos Postais",
        potential: "Potencial",
        pricaCatalogSubtitle: "Não crie duplicatas desnecessárias e prossiga com a criação de uma nova lista de preços",
        pricaCatalogTitle: "Você precisa de uma nova lista de preços?",
        preview: "Pré-visualização",
        previous: "Anterior",
        price: "Preço",
        priceCatalog: "Catálogo de Preços",
        priceCatalogs: "Catálogos de Preços",
        printCredentialsLabel: "Imprimir Rótulo de Credenciais",
        priorityOrders: "Pedidos Prioritários",
        processed: "Processado",
        processedUP: "PROCESSADO",
        province: "Província",
        purchaseCategory: "Categoria de Compra",
        //! Q
        qty: "Qtd",
        qtyBudgetCY: "Qtd no Orçamento CY",
        qtyOrdersYTD: "Qtd nos Pedidos YTD",
        queryApplyBudget: "Para quais clientes você quer aplicar o orçamento?",
        quantity: "Quantidade",
        //! R
        recDate: "Data de Recebimento",
        region: "Região",
        registrationDate: "Data de registro",
        remove: "Remover",
        repeated: "Repetido",
        required: "Campo {param} é obrigatório",
        reset: "Redefinir",
        reviewNotes: "Revise as notas antes de mudar o status para 'Trabalho em Progresso'",
        revertOrderStatusTo: "Reverter o status do Pedido para",
        rowTotal: "Total da linha",
        rowsTotalAfterDiscSurc: "Total das linhas após desconto/sobretaxa",
        //! S
        sales: "Vendas",
        saleTaxes: "Imposto do Vendas",
        save: "Salvar",
        saveOrderRows: "Salvar Linhas do Pedido",
        search: "Pesquisar",
        sector: "Setor",
        select: "Selecionar",
        selectLang: "Selecionar idioma",
        selectTenant: "Selecionar o Inquilino",
        selectValidStatisticalTag: "Selecione uma Tag Estatística válida",
        selection: "Seleção",
        sem: "SEM",
        semicolon: "Ponto e vírgula (;)",
        send: "Enviar",
        separator: "Separator",
        setAs: "Definir como",
        setFilterMetodo: "Defina um filtro para exportar dados para o Metodo",
        shipment: "Envio",
        shipmentDate: "Data de Envio",
        shippingCustomer: "Cliente de Envio",
        shippingCustomerLocation: "Localização do Cliente de Envio",
        shippingTo: "Enviando para",
        shipToDifferentCustomer: "Cliente de Invio",
        shopName: "Nome da Loja {r}",
        show: "Mostrar",
        showAll: "Mostrar tudo",
        stampDuty: "Stamp Duty",
        state: "Estado",
        statCategory: "Categoria Estatística",
        statisticalTag: "Tag Estatística",
        status: "Status",
        step1custTitle: "Sector, Channel and Purchase Category",
        step2custTitle: "Required informations",
        step4custTitle: "Financials info",
        stringSearch: "Pesquisa de String",
        subAgent: "Sub-Agente",
        subTotal: "Subtotal",
        subtotalDeliveryCollection: "Subtotal Delivery Collection",
        summary: "Resumo",
        surcharge: "Sobretaxa",
        surDis: "Sobretaxa/Desconto",
        surDisPerc: "Sobretaxa/Desconto %",
        suffix: "Sufixo",
        supervisorAgent: "Agente Supervisor",
        switchToParam: "Mudar para {param}",
        //! T
        tags: "Tags",
        tax: "Imposto",
        taxEligible: "Elegível para imposto",
        taxLiableForParam: "Imposto devido para {param}",
        taxPercentage: "Percentual de imposto",
        taxable: "Tributável",
        taxes: "Impostos",
        tenant: "Inquilino",
        title: "Título",
        to: "Para",
        toDo: "A Fazer",
        today: "Hoje",
        total: "Total",
        totalAmount: "Montante Total",
        totalQuantity: "Quantidade Total",
        totalWithTaxes: "Total com Impostos",
        transmitted: "TRANSMITIDO",
        translation: "Tradução",
        translations: "Traduções",
        travel: "Viagem",
        type: "Tipo",
        typeOrCopyPaste: "Digite ou Copie/Cole",
        //! U
        unified: "Unificado",
        unifyScale: "Unificar Escala",
        unset: "Desmarcar",
        update: "Atualizar",
        updateText: "Tem certeza que deseja atualizar este {param}?",
        upload: "Carregar",
        uploadCsv: "Carregar CSV",
        uploadCsvDoc: "Carregar Documento CSV",
        user: "Usuário",
        username: "Nome de usuário",
        users: "Usuários",
        //! V
        validItems: "Itens válidos",
        validParam: "{param} deve ser válido",
        value: "Valor",
        vat: "IVA",
        vatAssessment: "Avaliação do IVA",
        vatCode: "Código do IVA",
        vatNumber: "CNPJ {r}",
        vatPercentage: "Percentual de IVA",
        vatPerc: "IVA %",
        vatRate: "Taxa de IVA {r}",
        visible: "Visível",
        //! W
        website: "Site",
        week: "Semana",
        weight: "Peso",
        whitespaces: "Não são permitidos espaços",
        whenReady: "Quando estiver pronto, clique no botão para",
        with: "Com",
        withBudget: "Com Orçamento",
        withParam: "Com {param}",
        withoutBudget: "Sem Orçamento",
        withoutParam: "Sem {param}",
        workInProgress: "TRABALHO EM PROGRESSO",
        //! X
        //! Y
        year: "Ano",
        yes: "Sim",
        //! Z
        zone: "Zona",
        massiveDuplicate: "Duplicato Massivo"
    },
    "it-IT" : {
        copyFromParent: "Copia dal Padre",
        fulfilled: "Evasa",
        fulfilledWithOther: "Evasa con altro Corriere",
        printBordereau: "Stampa Bordereau",
        releaseNotes: "Note di rilascio",
        selectionIsEmpty: "La selezione è vuota. Seleziona un Documento",
        printInstrDocs: "Stampa Documenti Aggiuntivi",
        selectSender: "Seleziona cortesemente un Mittente",
        sender: "Mittente",
        customerReference: "Riferimento Cliente",
        codValue: "Valore del Contrassegno",
        selectionIsRequired: "È necessaria la selezione",
        contentDescription: "Descrizione Merce",
        contentDescriptionRequired: "Inserire la descrizione",
        pickASender: "Seleziona un Mittente",
        packageDetailsOf: "Dettagli dei colli del Documento ",
        widthCm: "Profondità (cm)",
        widthRequired: "La profondità è obbligatoria",
        lengthCm: "Lunghezza (cm)",
        lengthRequired: "La lunghezza è obbligatoria",
        heightCm: "Altezza (cm)",
        heightRequired: "L'altezza è obbligatoria",
        weightKg: "Peso (kg)",
        weightRequired: "Il peso è obbligatorio",
        addPackage: "Aggiungi Collo",
        package: "Collo",
        packages: "Colli",
        totalWeight: "Peso Totale",
        noPackagesFound: "Nessun Collo trovato",
        enterValidDecimalNumber: "Inserisci un numero decimale valido",
        tel: "Telefono",
        missingEmail: "Email mancante",
        invalidEmail: "Email non valida",
        missingPhone: "Telefono mancante",
        specialInstructions: "Istruzioni particolari",
        changeLang: "Lingua",
        shipTo: "Spedire a",
        allCustomers: "Tutti i clienti",
        informations: "Informazioni",
        shippingDoc: "Documento di Spedizione",
        recipient: "Destinatario",
        documentCustomer: "Intestatario",
        numero: "Numero",
        cancelShipment: "Annullare la Spedizione?",
        selectShippingOption: "Selezionare il Servizio di Spedizione",
        servicesLoading: "Caricamento stima Servizi di Spedizione disponibili...",
        noServicesAvailable: "TNT non ha reperito la stima dei costi di spedizione, verrà impostato in automatico il servizio più economico.",
        noServicesAvailableErrorIntro: "Errore restituito:",
        noServicesAvailableErrorNotSpecified: "non specificato",
        noPackages: "Nessun collo presente, impossibile procedere",
        printed : "Stampata",
        canceled : "Annullata",
        generatedWithErrors : "Generata con Errori",
        selectOneOrMoreTransportDocuments: "Seleziona uno o più Documenti di Spedizione per stamparne l'etichetta TNT",
        shipmentStatuses: "Stati Spedizione",
        alreadyHandledDocuments: "I documenti non selezionabili sono già stati gestiti in una Spedizione",
        documentsToPrint: "Documenti di Spedizione",
        printedShipments: "Spedizioni",
        cancelOrReprint: "Annulla o ristampa le Spedizioni inviate a TNT",
        remakeShipment: "Reimpostare la Spedizione?",
        selectOne: "Selezionare cortesemente almeno un Documento",
        selectSameCustomer: "Selezionare cortesemente Documenti appartenenti allo stesso Intestatario",
        selectSameRecipient: "Selezionare cortesemente Documenti appartenenti allo stesso Destinatario",
        timeZone: "Fuso orario",
        customerTimeZone: "Fuso Orario del Cliente :",
        customerTime: "Orario del Cliente :",
        yourTime: "Il tuo Orario :", 
        reload: "Ricarica",
        minOrder: "Ordine minimo",
        showInStoreLocator: "Mostra nello Store Locator",
        expectedChildren: "Figli attesi",
        missingFields: "Campi mancanti",
        rows: "Righe",
        noDescri: "Senza descrizione",
        cart: "Carrello",
        orderCustomer: "Cliente dell'ordine",
        childrenCustomer: "Cliente figlio",
        target: "Target",
        goals: "Goals",
        checkCoupon: "è un Coupon",
        confirmOrderState: "Conferma Stato Ordine",
        customersChildren: "Figli del Cliente",
        executiveClient: "Cliente Esecutivo",
        excludeFromStats: "Escludi dalle Statistiche",
        fax: "FAX",
        gender: "Genere",
        hasPaymentProblems: "Ha Problemi di Pagamento",
        itemCode: "Codice Articolo",
        leadStatus: "Stato Lead",
        leadValue: "Valore Lead",
        mailOnOrder: "Email su Ordine",
        merchandising: "Merchandising",
        messages: "Messaggi",          
        minimumOrder: "Minimo d'ordine",
        physicalPerson: "Persona Fisica",
        sdi: "SDI",
        tables: "Tabelle",
        task: "Attività",          
        taskAndMessages: "Attività e Messaggi",          
        //! A
        actions: "Azioni",
        active: "Attivo",
        activationDate: "Data di attivazione",
        add: "AGGIUNGI",
        address: "Indirizzo",
        addresses: "Indirizzi",
        accountingEmail: "Email amministrativa {r}",
        accountingType: "Tipo di contabilità",
        addToWhitelist: "Aggiungi alla 'lista bianca'",
        agent: "Agente",
        agents: "Agenti",
        alertMetodo: "Nella selezione corrente c'è almeno un ordine con lo stato ",
        all: "TUTTI",
        allShipments: "Tutte",
        allDone: "Completato",
        amount: "Importo",
        ampersand: "E commerciale (&)",
        anchor: "Ancora",
        anchors: "Ancore",
        applicant: "Richiedente",
        apply: "Applica",
        applyBudgetError: "Collezione clienti non valida! Applica i filtri correttamente",
        applyChangeTo: "Vuoi applicare questo cambiamento a tutti i clienti o solo a quelli senza un budget predefinito?",
        applyParam: "Applica {param}",
        applyTaxes: "Applica le tasse",
        applyTaxJar: "Applica le tasse da TaxJar",
        applyToParam: "Applica a {param}",
        assignedTo: "Assegnato a",
        authorization: "Autorizzazione",
        authorizeTo: "Autorizza a",
        author: "Autore",
        //! B
        b2bEmail: "Email B2B",
        b2bCredentialsHeader: "B2B Credentials",
        b2bCredentialsMessage: "You are about to create B2B credentials for this customer. Are you sure you want to proceed?",
        b2bAgentsHeader: "Sync ownership to Prestashop",
        b2bAgentsMessage: "You are about to sync customers of this agents to Prestashop. Are you sure you want to proceed?",
        barcode: "Codice a barre",
        barcodeLength: "Il codice a barre deve essere esattamente di 11 o 13 caratteri",
        barcodePrefix: "Prefisso del codice a barre",
        beforeDiscount: "Prima dello sconto",
        birthday: "Compleanno",
        bis: "Bis",
        box: "Scatola",
        brand: "Famiglia",
        brandCheck: "Verifica della marca",
        budget: "Budget",
        budgetCY: "Budget CY",
        budgetYTD: "Budget YTD",
        businessName: "Nome azienda {r}",
        //! C
        cancel: "Annulla",
        cannotRecognize: "Non possiamo riconoscere questi codici",
        catalog: "Catalogo",
        catalogs: "Cataloghi",
        ceg: "C&L",
        cellphone: "Cellulare",
        chain: "Catena",
        changeItemPicture: "Cambia immagine articolo",
        changePriceCatalog: "Cambia catalogo prezzi",
        changeStatus: "Cambia stato a ",
        channel: "Canale",
        chief: "Capo",
        chiefAgent: "Agente capo",
        childrenDistri: "Figli del distributore",
        city: "Città",
        clear: "Pulisci",
        clone: "Clona",
        close: "Chiudi",
        code: "Codice",
        collection: "Collezione",
        collectionCharges: "Spese di incasso",
        collectionExpenses: "Spese di incasso",
        collectionExpensesR1: "Le spese di incasso devono essere un numero",
        collectionExpensesR2: "Le spese di incasso devono essere uguali o superiori a 0",
        collections: "Collezioni",
        comma: "Virgola (,)",
        commercialSpecialist: "Specialista commerciale",
        compileBarcode: "ASSEGNA CODICE A BARRE",
        complete: "Completa",
        completed: "Completato",
        completedTasks: "compiti completati",
        confirm: "Conferma",
        contractualConversionRate: "TASSO DI CONVERSIONE CONTRATTUALE dalla VALUTA CONTABILE",
        contractualData: "Dati contrattuali",
        contactName: "Nome del contatto",
        country: "Nazione {r}",
        county: "Provincia {r}",
        crm: "CRM",
        currency: "Valuta",
        cutoffOrderDate: "Data di chiusura ordine",
        customer: "Cliente",
        customerCode: "Codice/Nome Cliente",
        customerMonitoring: "Monitoraggio Cliente",
        customerPaymentProblem: "Questo cliente ha problemi di pagamento.",
        customerLocation: "Indirizzo del Cliente",
        customers: "Clienti",
        //! D
        data: "Dati",
        date: "Data",
        day: "Giorno",
        deadlines: "Scadenze",
        default: "Informazioni principali",
        delete: "Delete",
        deleteMessage: "You are about to delete this customer. This operation is irreversible, and you will not be able to undo it. Are you sure you want to proceed?",
        deleteParam: "Elimina {param}",
        deleteHeader: "Confirm deletion",
        deleteText: "Sei sicuro di voler eliminare questo {param}?",
        deliveryCharges: "Spese di consegna",
        description: "Descrizione",
        descriptionBudgetAvailability: "Il budget del cliente, suddiviso per 'tag statistica', è disponibile come presentato qui.",
        descriptionLargeScaleBudgetInput: "Per inserire i budget su larga scala, usa il pulsante blu situato sopra la tabella, caratterizzato dall'icona di una banconota e un piccolo segno più. Seleziona se applicare il budget a tutti i clienti nella lista o solo a quelli che ne sono ancora sprovvisti.",
        descriptionModifyBudget: "È possibile modificare e/o eliminare i budget esistenti o aggiungere un nuovo budget associato a un tag statistica assente compilando l'apposito modulo.",
        dictionary: "Dizionario",
        disc: "Sconto",
        discP: "Sconto %",
        discount: "Sconto",
        discountAmount: "Importo dello sconto",
        dismiss: "DISMISS",
        distributor: "Distributore",
        docDate: "Data documento",
        docType: "Tipo di documento",
        document: "Testata",
        documentDate: "Data Documento",
        documentType: "Tipo di documento",
        done: "Completato",
        dontInsertParam: "Non inserire {param} o inseriscilo correttamente",
        download: "Scarica",
        downloadAll: "Scarica tutto",
        downloadDocuments: "Scarica documenti",
        downloadExample: "Scarica esempio",
        dropAnchor: "I want to drop the ANCHOR",
        due: "Scadenza",
        //! E
        edit: "Modifica",
        editParam: "Modifica {param}",
        edi: "EDI",
        email: "Email {r}",
        enasarco: "Enasarco",
        error: "Errore",
        errorNewOrder: "C'è un errore con i prezzi. Verifica la quantità e il prezzo della riga.",
        errorTryLater: "Errore! Riprova più tardi",
        estimatedDeliveryDate: "Data di consegna stimata",
        executor: "Esecutore",
        expectedStores: "Negozi previsti",
        expired: "Scaduto",
        expiration: "Scadenza",
        expirationDate: "Data di scadenza",
        explodeGroupCollection: "Esplodi gruppi e collezioni",
        export: "Esporta",
        exportToGrid: "Esporta in griglia",
        expectedValue: "Valore previsto",
        //! F
        facebook: "Facebook",
        family: "Famiglia",
        fiscalCode: "Codice fiscale {r}",
        fileInput: "Input file",
        filter: "Filtro",
        filters: "Filtri",
        financials: "Finanziari",
        firstName: "Nome",
        footer: "Piè di pagina",
        footerMonitoring: "Al {endDate} ({daysYTD} giorni dall'inizio dell'anno)",
        forceSync: "Forza sincronizzazione",
        fourDays: "4 giorni",
        from: "Da",
        fullAddress: "Indirizzo completo",
        //! G
        generalData: "Dati generali",
        gift: "Regalo",
        grid: "Griglia",
        group: "Gruppo",
        groupIdentifier: "Identificatore del gruppo",
        groupOrIndipendent: "Gruppo/Indipendente",
        groups: "Gruppi",
        //! H
        hasCustomerBudget: "Ha budget cliente",
        hasEngravers: "Ha incisori",
        hasExpiration: "Ha una scadenza",
        header: "Intestazione",
        height: "Altezza",
        hide: "Nascondi",
        hideParam: "Nascondi {param}",
        historicalOrder: "Ordine storico",
        howManyMonths: "Quanti mesi?",
        //! I
        iban: "Iban {r}",
        id: "Id",
        image: "Immagine",
        inactive: "Inattivo",
        inactiveItems: "Articoli inattivi",
        individual: "Individuale",
        info: "Info",
        infoChannel: "Un 'canale di vendita' si riferisce ai diversi metodi o canali attraverso cui un'azienda vende i suoi prodotti o servizi ai clienti. Rappresenta le varie vie o piattaforme utilizzate da un'azienda per raggiungere e coinvolgere il suo mercato di riferimento, consentendo ai clienti di effettuare acquisti. I canali di vendita possono includere negozi fisici, siti di e-commerce, app mobili, team di vendita diretta, grossisti, distributori e qualsiasi altro intermediario o piattaforma coinvolti nel processo di vendita di prodotti o servizi. Ogni canale di vendita ha caratteristiche, vantaggi e portata dei clienti unici e le aziende spesso impiegano più canali di vendita per soddisfare le diverse preferenze dei clienti e ampliare la loro presenza sul mercato.",
        infoPurchaseCategory: "Il termine 'tipo di attività' si riferisce alla classificazione o categorizzazione di un'azienda in base alla sua struttura legale, proprietà e caratteristiche operative. Aiuta a definire la natura, l'organizzazione e la governance di un'entità commerciale. Il tipo di attività determina fattori come la responsabilità, la tassazione, i diritti di proprietà e i requisiti normativi.",
        infoSector: "Il termine 'campo delle vendite' si riferisce generalmente all'area o al dominio specifico all'interno del campo più ampio delle vendite. Comprende le attività, le strategie e le tecniche relative alla vendita di prodotti o servizi in un particolare segmento di mercato o industria. Il campo delle vendite può includere vari ruoli e funzioni, come rappresentanti di vendita, manager delle vendite, operazioni di vendita e personale di supporto alle vendite. All'interno del campo delle vendite, i professionisti si concentrano tipicamente sullo sviluppo e l'implementazione di strategie di vendita efficaci, sulla costruzione di relazioni con i clienti, sulla generazione di lead, sulla negoziazione e chiusura di affari e sul raggiungimento degli obiettivi di vendita. Possono anche impegnarsi nella ricerca di mercato, nell'analisi dei concorrenti e nella gestione delle relazioni con i clienti per migliorare le loro prestazioni di vendita. In sintesi, il campo delle vendite rappresenta l'area specializzata di competenza e le attività relative alle vendite all'interno di un'industria o segmento di mercato specifico.",
        internalNotes: "Note Interne",
        insertLink: "Inserisci LINK",
        insertValidParam: "Inserisci un {param} valido",
        incomplete: "Incompleto",
        invoicingCustomer: "Cliente fatturazione {r}",
        invoicingCustomerLocation: "Indirizzo del cliente di fatturazione",
        invoiceToDifferentCustomer: "Fattura a un cliente diverso",
        invalidParam: "{param} non valido",
        items: "Articoli",
        item: "Articolo",
        itemsQty: "quantità articoli",
        itemsPriceResume: "Articoli: {q}. Prezzo minimo: {min}, Prezzo massimo: {max}",
        //! J
        //! K
        kit: "Kit",
        kits: "Kit",
        //! L
        labelGlobalBudget: "Budget globale",
        labelHighBudget: "Budget alto",
        labelLowBudget: "Budget basso",
        labelWithoutBudget: "Senza budget",
        lang: "Lingua",
        language: "Lingua",
        lastName: "Cognome",
        lastOrderDate: "Data dell'ultimo ordine",
        lastOrderDateInRange: "Intervallo data dell'ultimo ordine",
        leaveComment: "Lascia un commento",
        lessThan: "{param} deve essere inferiore a {qty} caratteri",
        lfu: "LFU",
        line: "Linea",
        listSearch: "Ricerca elenco",
        loading: "Caricamento... Attendere prego",
        loadCsv: "Carica CSV",
        loadMore: "Carica altro",
        loadedValidItems: "Articoli validi caricati",
        loan: "Comodato d'uso",
        location: "Posizione",
        locations: "Posizioni",
        logsAndMessages: "Log e messaggi",
        //! M
        machine: "Macchina",
        machines: "Macchine",
        mainInfo: "Informazioni principali",
        mandatoryShippingDate: "Data di spedizione obbligatoria",
        management: "Gestione",
        marketingData: "Dati di marketing",
        marketingEmail: "Email di marketing",
        marketingFilters: "Filtri di marketing",
        maxParam: "Max {param}",
        minNotReached: "Ordine minimo ({param}) non raggiunto",
        minParam: "Min {param}",
        missing: "MANCANTE",
        month: "Mese",
        monitoring: "Monitoraggio",
        movingQty: "Quantità in movimento",
        //! N
        na: "n.d.",
        name: "Nome",
        nearestDistributor: "Distributore di riferimento",
        neverExpire: "Mai scaduto",
        newParam: "Aggiungi {param}",
        next: "Avanti",
        no: "No",
        noData: "NESSUN DATO DA MOSTRARE",
        noDataRefresh: "NESSUN DATO - Clicca per aggiornare",
        noTasks: "Nessun compito disponibile. Buona giornata",
        notExecutiveCustomers: "Clienti non esecutivi",
        notGift: "Non è un regalo",
        notLoan: "Non è un comodato d'uso",
        notObsolete: "Non obsoleto",
        note: "Nota",
        notes: "Note",
        nr: "Nr",
        //! O
        obsolete: "Obsoleto",
        obsoleteItems: "Articoli obsoleti",
        onlyEffectiveCustomers: "Solo clienti efficaci",
        onlyExecutiveCustomers: "Solo clienti esecutivi",
        onlyPotentialLeads: "Solo potenziali clienti",
        open: "Apri",
        openDraft: "APRI BOZZA",
        order: "Ordine",
        orderDate: "Data dell'ordine",
        orderHasBeen: "Questo ordine è stato",
        orderIs: "Questo ordine è un",
        orderLanguage: "Lingua dell'ordine",
        orderRows: "Righe dell'ordine",
        orders: "Ordini",
        orderStatisticalTag: "Tag statistica dell'ordine",
        orderStatus: "Stato dell'ordine",
        orderStatusText: "Lo stato di questo ordine è",
        orderType: "Tipologia di Ordine",
        orderWaiting: "Questo ordine è in attesa di",
        ordersContent: "Contenuto degli ordini",
        ordersPY: "Ordini PY",
        ordersYTD: "Ordini YTD",
        ourChampion: "Il nostro campione",
        other: "Altro",
        ownership: "Assegnazioni",
        //! P
        packagingType: "Tipo di imballaggio",
        pallet: "Pallet",
        palletHeight: "Altezza pallet",
        palletHeightCm: "Altezza massima pallet (cm)",
        parent: "Padre",
        parentCode: "Codice Padre",
        partner: "Partner",
        payment: "Pagamento",
        paymentInformations: "Informazioni di pagamento",
        paymentMethod: "Metodo di pagamento {r}",
        paymentMethods: "Metodi di pagamento",
        paymentTerm: "Termine di pagamento {r}",
        paymentTerms: "Termini di pagamento",
        percentage: "Percentuale",
        phone: "Telefono",
        pipe: "Pipe (|)",
        pleaseConfirm: "Conferma se desideri procedere con questa azione.",
        po: "Rif. Ordine Cliente",
        postalCode: "Codice postale",
        postalCodes: "Codici postali",
        potential: "Potenziale",
        pricaCatalogSubtitle: "Non creare duplicati non necessari e procedi con la creazione di un nuovo listino prezzi",
        pricaCatalogTitle: "Hai bisogno di un nuovo listino prezzi?",
        preview: "Anteprima",
        previous: "Precedente",
        price: "Prezzo",
        priceCatalog: "Listino",
        priceCatalogs: "Listini",
        printCredentialsLabel: "Stampa etichetta credenziali",
        priorityOrders: "Ordini prioritari",
        processed: "Elaborato",
        processedUP: "ELABORATO",
        province: "Provincia",
        purchaseCategory: "Categoria di acquisto",
        //! Q
        qty: "Quantità",
        qtyBudgetCY: "Quantità su Budget CY",
        qtyOrdersYTD: "Quantità su Ordini YTD",
        queryApplyBudget: "A quali clienti vuoi applicare il budget?",
        quantity: "Quantità",
        //! R
        recDate: "Data di ricezione",
        region: "Regione",
        registrationDate: "Data di registrazione",
        remove: "Rimuovi",
        repeated: "Ripetuto",
        required: "Il campo {param} è obbligatorio",
        reset: "Reimposta",
        reviewNotes: "Rivedi le note prima di cambiare lo stato a 'Lavori in corso'",
        revertOrderStatusTo: "Riporta lo stato dell'ordine a",
        rowTotal: "Totale riga",
        rowsTotalAfterDiscSurc: "Totale righe dopo sconto/sovrapprezzo",
        //! S
        sales: "Vendite",
        saleTaxes: "Imposte di Vendita",
        save: "Salva",
        saveOrderRows: "Salva righe ordine",
        search: "Cerca",
        sector: "Settore",
        select: "Seleziona",
        selectLang: "Seleziona lingua",
        selectTenant: "Seleziona l'inquilino",
        selectValidStatisticalTag: "Seleziona un tag statistico valido",
        selection: "Selezione",
        sem: "SEM",
        semicolon: "Punto e virgola (;)",
        send: "Invia",
        separator: "Separatore",
        setAs: "Imposta come",
        setFilterMetodo: "Imposta un filtro per esportare i dati a Metodo",
        shipment: "Spedizione",
        shipmentDate: "Data di spedizione",
        shippingCustomer: "Cliente di spedizione",
        shippingCustomerLocation: "Indirizzo del cliente di spedizione",
        shippingTo: "Spedizione a",
        shipToDifferentCustomer: "Spedire ad altro Cliente",
        shopName: "Nome del negozio {r}",
        show: "Mostra",
        showAll: "Mostra tutto",
        stampDuty: "Imposta di Bollo",
        state: "Stato",
        statCategory: "Categoria statistica",
        statisticalTag: "Tag statistico",
        status: "Stato",
        step1custTitle: "Settore, Canale and categoria d'Acquisto",
        step2custTitle: "Info necessarie",
        step4custTitle: "Info Economico/Finanziarie",
        stringSearch: "Ricerca per stringa",
        subAgent: "Sub-agente",
        subTotal: "Subtotale",
        subtotalDeliveryCollection: "Subtotal Spese di trasporto",
        summary: "Sommario",
        surcharge: "Sovrapprezzo",
        surDis: "Sovrapprezzo/Sconto",
        surDisPerc: "Sovrapprezzo/Sconto %",
        suffix: "Suffisso",
        supervisorAgent: "Agente supervisore",
        switchToParam: "Passa a {param}",
        //! T
        tags: "Tag",
        tax: "Tassa",
        taxEligible: "Idoneo alla tassa",
        taxLiableForParam: "Responsabile della tassa per {param}",
        taxPercentage: "Percentuale tassa",
        taxable: "Imponibile",
        taxes: "Tasse",
        tenant: "Inquilino",
        title: "Titolo",
        to: "A",
        toDo: "Da fare",
        today: "Oggi",
        total: "Totale",
        totalAmount: "Importo totale",
        totalQuantity: "Quantità totale",
        totalWithTaxes: "Totale con tasse",
        transmitted: "TRASMESSO",
        translation: "Traduzione",
        translations: "Traduzioni",
        travel: "Viaggio",
        type: "Tipo",
        typeOrCopyPaste: "Scrivi o copia/incolla",
        //! U
        unified: "Unificato",
        unifyScale: "Unifica scala",
        unset: "Deseleziona",
        update: "Modifica",
        updateText: "Sei sicuro di voler aggiornare questo {param}?",
        upload: "Carica",
        uploadCsv: "Carica CSV",
        uploadCsvDoc: "Carica documento CSV",
        user: "Utente",
        username: "Nome utente",
        users: "Utenti",
        //! V
        validItems: "Articoli validi",
        validParam: "{param} deve essere valido",
        value: "Valore",
        vat: "IVA",
        vatAssessment: "Importo IVA",
        vatCode: "Codice IVA",
        vatNumber: "Partita IVA {r}",
        vatPercentage: "Percentuale IVA",
        vatPerc: "IVA %",
        vatRate: "Aliquota IVA {r}",
        visible: "Visibile",
        //! W
        website: "Sito web",
        week: "Settimana",
        weight: "Peso",
        whitespaces: "Non sono consentiti spazi",
        whenReady: "Quando sei pronto, clicca sul pulsante per",
        with: "Con",
        withBudget: "Con budget",
        withParam: "Con {param}",
        withoutBudget: "Senza budget",
        withoutParam: "Senza {param}",
        workInProgress: "LAVORI IN CORSO",
        //! X
        //! Y
        year: "Anno",
        yes: "Sì",
        //! Z
        zone: "Zona",
        massiveDuplicate: "Duplicazione massiva"
    }
    
  }

var numeral = Vue.prototype.numeral = require('numeral');

var CryptoJS = require("crypto-js");

let initOptions = {
    // url: 'https://auth.myfamily.network/auth',
    url: process.env.VUE_APP_AUTH_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: 'nikita',
    onLoad: 'login-required'
}

let keycloak = Keycloak(initOptions);

Vue.prototype.$keycloak = keycloak;

let tok1 = localStorage.getItem('tok1');
let tok2 = localStorage.getItem('tok2');

let initParam = {
    onLoad: initOptions.onLoad,
    "checkLoginIframe": false
}

if (!!tok1 && !!tok2) {
    initParam.token = tok1;
    initParam.refreshToken = tok2;
}

keycloak.init(initParam).then((auth) => {
    if (!auth) {
        window.location.reload();
    }

    const NikitaSdk = require('@myfamilysrl/nikita_sdk');
    NikitaSdk.ApiClient.instance.basePath = process.env.VUE_APP_BACKEND_BASEPATH;
    NikitaSdk.ApiClient.instance.defaultHeaders = {};
    NikitaSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    const BcSyncSdk = require('@myfamilysrl/bcsync_sdk');
    BcSyncSdk.ApiClient.instance.basePath = process.env.VUE_APP_BCSYNC_BASEPATH;
    BcSyncSdk.ApiClient.instance.defaultHeaders = {};
    BcSyncSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    const PodioSdk = require('@myfamilysrl/podio2nikita_sdk');
    PodioSdk.ApiClient.instance.basePath = process.env.VUE_APP_PODIOSYNC_BASEPATH;
    PodioSdk.ApiClient.instance.defaultHeaders = {};
    PodioSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    const IamSdk = require('@myfamilysrl/iam_sdk');
    IamSdk.ApiClient.instance.basePath = process.env.VUE_APP_IAM_BASEPATH;
    IamSdk.ApiClient.instance.defaultHeaders = {};
    IamSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    const IntesaEdiSdk = require('@myfamilysrl/intesaedi-sdk');
    IntesaEdiSdk.ApiClient.instance.basePath = process.env.VUE_APP_EDI_BASEPATH;
    IntesaEdiSdk.ApiClient.instance.defaultHeaders = {};
    IntesaEdiSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    const TntSdk = require('@myfamilysrl/tntsync_sdk');
    TntSdk.ApiClient.instance.basePath = process.env.VUE_APP_TNT_BASEPATH;
    TntSdk.ApiClient.instance.defaultHeaders = {};
    TntSdk.ApiClient.instance.defaultHeaders["Accept"] = "application/json";

    new Vue({
        components: {
            Spreadsheet,
            SpreadsheetSheet,
            Map,
            MapMarker,
            MapLayer,
            MapInstaller,
            Chat,
            Upload,
            Editor,
            Chart,
            ChartSeriesItem,
            Sparkline,
            SparklineSeriesItem,
            StockChart,
        },
        store,
        router,
        vuetify,
        i18n: new VueI18n({
          locale: localStorage.getItem('lang') || 'en-EN',
          messages: messages
        }),
        render: h => h(App),
    }).$mount('#app');

    let payload = {
        idToken: keycloak.idToken,
        accessToken: keycloak.token
    }

    if (keycloak.token && keycloak.idToken && keycloak.token !== '' && keycloak.idToken !== '') {
        try {
            let parsedAccessToken = JSON.parse(atob(keycloak.token.split('.')[1]));
            store.set("user/tenant", localStorage.getItem('tenantUuid') || parsedAccessToken.tenant_uuid[0]);
            store.set("tenantConfiguration/loadTenantConfiguration!", parsedAccessToken.tenant_uuid);
        } catch (e) {
        }
        store.set("auth/tokens", payload);
        localStorage.setItem('tok1', keycloak.token);
        localStorage.setItem('tok2', keycloak.refreshToken);
        NikitaSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        BcSyncSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        IamSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        IntesaEdiSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        PodioSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        TntSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
        keycloak.loadUserProfile().then((profile) => {
            profile.userRoles = keycloak.realmAccess.roles;
            store.set("auth/userProfile", profile);
        });
    } else {
        store.commit("logout");
    }

//Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                let payload = {
                    idToken: keycloak.idToken,
                    accessToken: keycloak.token
                }
                if (keycloak.token && keycloak.idToken && keycloak.token !== '' && keycloak.idToken !== '') {
                    try {
                        let parsedAccessToken = JSON.parse(atob(keycloak.token.split('.')[1]));
                        store.set("user/tenant", localStorage.getItem('tenantUuid') || parsedAccessToken.tenant_uuid[0]);
                        store.set("tenantConfiguration/loadTenantConfiguration!", parsedAccessToken.tenant_uuid);
                    } catch (e) {
                    }
                    store.set("auth/tokens", payload);
                    localStorage.setItem('tok1', keycloak.token);
                    localStorage.setItem('tok2', keycloak.refreshToken);

                    NikitaSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
                    BcSyncSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
                    IamSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
                    IntesaEdiSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
                    PodioSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;
                    TntSdk.ApiClient.instance.defaultHeaders["Authorization"] = "Bearer " + payload.accessToken;

                    keycloak.loadUserProfile().then((profile) => {
                        profile.userRoles = keycloak.realmAccess.roles;
                        store.set("auth/userProfile", profile);
                    });
                }
                !!Vue.$log && Vue.$log.info('Token refreshed' + refreshed);
            } else {
            }
        }).catch(() => {
            !!Vue.$log && Vue.$log.error('Failed to refresh token');
        });
    }, 6000)

}).catch(() => {
    !!Vue.$log && Vue.$log.error("Authenticated Failed");
});

